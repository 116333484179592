import React from 'react';
import { Row, Col, Typography, Divider, Button } from 'antd';
const { Paragraph, Text, Title } = Typography;

export const features = {
  sentence: <div style={{fontSize: '18px', lineHeight: '18px', textAlign: 'center'}}>
      <Paragraph>Bright, open floor plans.</Paragraph>
      <Paragraph><Text mark strong>Full-size washer and dryer</Text> in every unit.</Paragraph>
      <Paragraph><Text mark strong>Upgraded stainless steel appliances</Text>.</Paragraph>
      <Paragraph>Bright new laminate wood flooring.</Paragraph>
      <Paragraph>Lots of <Text mark strong>storage and closet space.</Text></Paragraph>
      <Paragraph>Granite kitchen counters.</Paragraph>
      <Paragraph>Fast acting individual mini split <Text mark strong>heating and air conditions</Text>.</Paragraph>
      <Paragraph>Separate water system and <Text mark strong>individual water heaters</Text> in each unit</Paragraph>
      <Paragraph><Text mark strong>Dual pane windows</Text> with efficient low energy glazing</Paragraph>
      <Paragraph>Controlled access entry with RFID and intercom</Paragraph>
      <Paragraph>Cameras at all entry and exits to building for added security</Paragraph>
      <Paragraph>Secure, easy-access <Text mark strong>parking</Text> located beneath the building</Paragraph>
  </div>,
images: [
  {
    src: 'assets/pics/pic1.jpg',
    caption: '',
    thumb: 'assets/pics/pic1.jpg'
  },
  {
    src: 'assets/pics/pic2.jpg',
    caption: '',
    thumb: 'assets/pics/pic2.jpg'
  },
  // {
  //   src: 'assets/pics/pic3.jpg',
  //   caption: '',
  //   thumb: 'assets/pics/pic3.jpg'
  // },
  {
    src: 'assets/pics/pic4.jpg',
    caption: '',
    thumb: 'assets/pics/pic4.jpg'
  },
  {
    src: 'assets/pics/pic5.jpg',
    caption: '',
    thumb: 'assets/pics/pic5.jpg'
  },
  {
    src: 'assets/pics/pic6.jpg',
    caption: '',
    thumb: 'assets/pics/pic6.jpg'
  },
  {
    src: 'assets/pics/pic7.jpg',
    caption: '',
    thumb: 'assets/pics/pic7.jpg'
  }
  ]
}
