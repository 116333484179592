import React from 'react';

import { Row, Col, Typography, Divider, Button } from 'antd';

import { ApplicationData } from '@bwb-buildings-mono/entities';
import * as UI from '@bwb-buildings-mono/ui';

import { features } from './features'
import { amentities } from './ammenities'

const { Paragraph, Text, Title } = Typography;

const PHONE_NUMBER = '+1(213)212-7655';
const EMAIL = 'manager@720kingsley.com';
const ADDRESS_NAME = '720 Kingsley Apartments';
const ADDRESS_1 = '720 S. Kingsley Drive';
const ADDRESS_2 = 'Los Angeles, CA 90005';

const tours =[
  {
      name: '1BR Loft, 1 BA - ~600 SF',
      url: 'https://my.matterport.com/show/?model=p8aA9B5rMKV&play=1',
      // url: 'https://www.zillow.com/view-3d-home/23690e20-af30-4857-b56d-bacbee3884e9'
  },
  {
      name: '2BR Loft, 2BA - ~750 SF',
      url: 'https://my.matterport.com/show/?model=DgPrhzkwNi8&play=1',
      // url: 'https://www.zillow.com/view-3d-home/fb7ce52f-9c38-47a6-bc24-795ce42af05d'
  }
];

export const AppData:ApplicationData = {
  footer: <div>
      <div>
          <Row>
              <Col md={24} xs={0}>
                  { ADDRESS_NAME } — {ADDRESS_1}, { ADDRESS_2 } — <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
              </Col>
              <Col md={0} xs={24}>
                  { ADDRESS_NAME }<br/>
                  { ADDRESS_1 }<br/>
                  { ADDRESS_2 }<br/>
                  <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
              </Col>
          </Row>
      </div>
      <div style={{fontSize: '80%', marginTop: '24px'}}>
          <div>{ ADDRESS_NAME } is located in or around Koreatown, Wilshire Center, Wilshire Park, Central LA, Macarthur Park, Los Angeles</div>
          <div>Thank You For Looking, We Look Forward to Helping You!</div>
      </div>
  </div>,
  sections: [
      {
          element: UI.Logo,
          props: {
              logo: <img style={{height: '50px'}} src="./assets/logo.png"/>,
              address: <div style={{lineHeight: 1.2 }}>
                  <div style={{fontSize: '110%'}}>{ ADDRESS_NAME }</div>
                  <div style={{fontSize: '80%'}}>{ ADDRESS_1 }</div>
                  <div style={{fontSize: '80%'}}>{ ADDRESS_2 }</div>
                  <div style={{fontSize: '80%'}}><a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a></div>
              </div>
          }
      },
      {
          isNav: true,
          element: UI.Nav,
          props: {
              logo: {
                  logo: <img style={{height: '50px'}} src="./assets/logo.png"/>,
                  address: <div style={{lineHeight: 1}}>
                      <div style={{fontSize: '110%'}}>{ ADDRESS_NAME }</div>
                      <div style={{fontSize: '80%'}}>{ ADDRESS_1 }</div>
                      <div style={{fontSize: '80%'}}>{ ADDRESS_2 }</div>
                      <div style={{fontSize: '80%'}}>{PHONE_NUMBER}</div>
                  </div>
              },
              rightSide: (
                  <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL}/>
              ),
              rightSideModal: (
                  <div>
                      <Paragraph>
                          <UI.VirtualToursButton tours={tours}/>
                      </Paragraph>
                      <Paragraph>
                          <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL}/>
                      </Paragraph>
                  </div>
              )
          }
      },
      {
          element: UI.HeroImage,
          props: {
            backgroundSize: 'contain',
            image: './assets/pics/hero.jpg'
          }
      },
      {
          element: UI.Tagline,
          props: {
              tagline: 'New apartments in central Koreatown, Los Angeles.',
              undertag: <div>
                  <UI.VirtualToursButton tours={tours}/>
                  <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL}/>
              </div>
          }
      },
      {
          element: UI.SectionTextCarousel,
          label: UI.NAV_ITEM.UNITS,
          nav: 'Units',
          props: {
              title: 'Quality Apartments',
              sections: [features]
          }
      },
      {
          element: UI.SectionTextCarousel,
          label: UI.NAV_ITEM.AMENITIES,
          nav: 'Amenities',
          props: {
              title: 'Community Amenities',
              sections: [amentities]
          }
      },
      {
          element: UI.SectionImages,
          label: UI.NAV_ITEM.FLOORPLANS,
          nav: 'Floorplans',
          props: {
              title: 'Floorplans',
              images: [
                  "./assets/floorplans/720K - 104.png",
                  "./assets/floorplans/720K - 204.png"
              ]
          }
      },
      {
        element: UI.SectionSideBySide,
        props: {
            leftSide: <div>
                <h3>Schedule a tour with our on-site manager:</h3>
                <div style={{marginTop: 24}}>
                    <h1 style={{}}><a href={`tel:${PHONE_NUMBER}`}>{ PHONE_NUMBER }</a></h1>
                    <div style={{fontSize: '80%'}}>Call anytime!</div>
                </div>
                <div style={{marginTop: 24}}>
                    <h1 style={{}}><a href={`mailto:${EMAIL}`}>{ EMAIL }</a></h1>
                    <div style={{fontSize: '80%'}}>Email us what you are looking for and some times that work for you.</div>
                </div>
                <div style={{marginTop: 24}}>
                    <Paragraph>
                        <UI.VirtualToursButton tours={tours}/>
                    </Paragraph>
                    <Paragraph>
                        <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL}/>
                    </Paragraph>
                </div>
            </div>,
            rightSide: (
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.4076965920917!2d-118.30504888478472!3d34.05906168060395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2b883ce72e8ab%3A0x50548776bc87e89d!2s720%20S%20Kingsley%20Dr%2C%20Los%20Angeles%2C%20CA%2090005!5e0!3m2!1sen!2sus!4v1628299131848!5m2!1sen!2sus" width="400" height="400" frameBorder={0} style={{border:"1px solid #CCC"}}></iframe>
            )
        }
    }
  ]
}
