import React, { useState } from 'react';
import { Button, Modal } from 'antd';

import './virtual-tours.scss';

interface Tour {
  name: string;
  url: string;
}

/* eslint-disable-next-line */
export interface VirtualToursButtonProps {
  tours: Tour[];
}

export const VirtualToursButton = (props: VirtualToursButtonProps) => {
  const [showModal, setShowModal] = useState(false);
  const [activeTour, setActiveTour] = useState(0);

  return (
    <React.Fragment>
      <Button
        style={{ margin: '0px 12px' }}
        type="primary"
        size="large"
        onClick={() => setShowModal(true)}
      >
        Virtual Tours Available!
      </Button>
      <Modal
        width="98%"
        title="Virtual Tours"
        style={{ top: 20, height: '98%' }}
        className="fullscreenModal"
        visible={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
        footer={null}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
      >
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {activeTour > 0 && activeTour < props.tours.length ? (
            <iframe
              title="Virtual Tour"
              width="100%"
              height="100%"
              seamless={true}
              frameBorder={0}
              allowFullScreen={true}
              src={props.tours[activeTour].url}
            ></iframe>
          ) : null}

          <div style={{ paddingTop: 6, width: '100%', textAlign: 'center' }}>
            {props.tours.map((tour, i) => {
              return (
                <div
                  style={{ marginRight: 12, height: 50 }}
                  className={
                    i == activeTour ? 'ant-btn ant-btn-primary' : 'ant-btn'
                  }
                  onClick={() => setActiveTour(i)}
                  key={i}
                >
                  <div>{tour.name}</div>
                  <div style={{ fontSize: '85%' }}>
                    {i == activeTour ? 'Viewing Now' : 'Click To View'}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default VirtualToursButton;
