import React from 'react';

import { MainApp } from '@bwb-buildings-mono/ui';

import { AppData } from '../data';

import 'antd/dist/antd.css';
import './app.scss';

export const App = () => {
  return (
    <div>
      <div style={{padding: 10, textAlign: 'center', fontSize: 20, backgroundColor: '#ffedd7', color: '#B77421'}}>Part of <a style={{color: '#B77421', fontWeight: 'bold', textDecoration:'underline'}} href="https://www.thekingsleycollection.com">The Kingsley Collection</a>.</div>
      <MainApp {...AppData}/>
    </div>
  );
};

export default App;
