import React from 'react';
import { Row, Col, Typography, Divider, Button } from 'antd';
const { Paragraph, Text, Title } = Typography;

export const amentities = {
  sentence: (
    <div style={{ fontSize: '18px', lineHeight: '18px', textAlign: 'center' }}>
      <Paragraph>
        <Text mark strong>
          Great location
        </Text>{' '}
        close to restaurants, shops & more!
      </Paragraph>
      <Paragraph>
        <Text mark strong>
          Parking
        </Text>{' '}
        in a secure garage.
      </Paragraph>
      <Paragraph>
        Intercom entry and{' '}
        <Text mark strong>
          secure access
        </Text>
        .
      </Paragraph>
      {/* <Paragraph>Professional <Text mark strong>&nbsp;manager next door&nbsp;</Text>.</Paragraph> */}
      <Paragraph>
        <Text mark strong>
          Electrical Vehicle Chargers
        </Text>{' '}
        Available.
      </Paragraph>
      <Paragraph>Cat friendly, no dogs.</Paragraph>
    </div>
  ),
  images: [
    {
      src: 'assets/pics/ev.jpg',
      caption: '',
      thumb: 'assets/pics/ev.jpg',
    },
    {
      src: 'assets/pics/parking.jpg',
      caption: '',
      thumb: 'assets/pics/parking.jpg',
    },
  ],
};
